import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {Trans, useTranslation} from 'react-i18next'
import {
    getTicketBatch,
    getTicketDescription,
    getTicketName,
    setTicketBatch, setTicketBatchError,
    setTicketDescription, setTicketDescriptionError,
    setTicketName, setTicketNameError
} from '../../store/mintSlice'
import {AppDispatch} from '../../store/store'
import {getImageUrl, getSelectedMintCollection} from '../../store/appSlice'
import {getSelectedEventName} from '../../store/eventsSlice'
import {getSelectedOrganizerName} from '../../store/organizersSlice'
import {getSelectedTicketLevelName} from '../../store/ticketsSlice'
import {ConnectWalletButton, SignAuthMessage} from '../wallet'
import {getUser} from '../../store/authSlice'
import {AlertElement, ButtonElement, InputElement, TextareaElement} from '../elements'
import {SelectEvent, SelectOrganizer, SelectTicketLevel} from '../profile'
import {ChevronDownIcon} from '../icons'
import {ThemeContext} from '../../context'
import {PropertiesBlock, SelectImageBlock} from '../blocks'

interface propsType {
    maxBatchAmount: number
    onSubmit: () => void
}

const MintBlock = (props: propsType) => {
    const {t} = useTranslation()
    const {showcaseType} = useContext(ThemeContext)
    const [advancedOpened, setAdvancedOpened] = useState(false)
    const [eventError, setEventError] = useState('')
    const [firstLaunch, setFirstLaunch] = useState(true)
    const [levelError, setLevelError] = useState('')
    const [organizerError, setOrganizerError] = useState('')
    const eventName = useSelector(getSelectedEventName)
    const imageUrl = useSelector(getImageUrl)
    const organizerName = useSelector(getSelectedOrganizerName)
    const selectedCollection = useSelector(getSelectedMintCollection)
    const ticketBatch = useSelector(getTicketBatch)
    const ticketDescription = useSelector(getTicketDescription)
    const ticketLevelName = useSelector(getSelectedTicketLevelName)
    const ticketName = useSelector(getTicketName)
    const user = useSelector(getUser)

    const [{wallet}] = useConnectWallet()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (firstLaunch) {
            setFirstLaunch(false)
            return
        }

        if (!advancedOpened) {
            setAdvancedOpened(true)
        }
    }, [selectedCollection])

    const checkButtonDisabled = (): boolean => {
        return eventError !== '' || levelError !== '' || organizerError !== '' || imageUrl.error.status ||
            ticketBatch.error.status || ticketDescription.error.status || ticketName.error.status
    }
    const onChangeBatchHandler = (value: string) => {
        let val = parseInt(value)
        if (value === '' || isNaN(val)) {
            dispatch(setTicketBatchError(t('error.mustEnter', {name: t('form.label.batchAmount')})))
            dispatch(setTicketBatch(null))
            return
        }

        dispatch(setTicketBatchError(null))
        val = val > props.maxBatchAmount ? props.maxBatchAmount : val
        dispatch(setTicketBatch(val))
    }
    const onChangeDescription = (value: string) => {
        if (value === '') {
            dispatch(setTicketDescriptionError(t('error.mustEnter', {name: t('form.label.description')})))
        } else if (value.length < 3) {
            dispatch(setTicketDescriptionError(t('error.mustBeGreater', {name: t('form.label.description'), value: 2})))
        } else {
            dispatch(setTicketDescriptionError(null))
        }
        dispatch(setTicketDescription(value))
    }
    const onChangeTicketName = (value: string) => {
        if (value === '') {
            dispatch(setTicketNameError(t('error.mustEnter', {name: t('form.label.ticketName')})))
        } else {
            dispatch(setTicketNameError(null))
        }
        dispatch(setTicketName(value))
    }
    const submitHandler = () => {
        let error = false
        if (organizerName === '') {
            error = true
            setOrganizerError(t('error.organizerNotSelected'))
        } else {
            setOrganizerError('')
        }
        if (eventName === '') {
            error = true
            setEventError(t('error.eventNotSelected'))
        } else {
            setEventError('')
        }
        if (ticketLevelName === '') {
            error = true
            setLevelError(t('error.levelNotSelected'))
        } else {
            setLevelError('')
        }

        if (error && !advancedOpened) {
            setAdvancedOpened(true)
        }
        props.onSubmit()
    }

    return <>
        <div className="col-lg-9 col-xl-8">
            <div className="mb-6">
                <div className="row align-items-center mb-3 gx-3">
                    <div className="col-sm mb-2 mb-sm-0 order-sm-2">
                        <small> <span className="text-muted">{t('word.event')} </span>{eventName}</small>
                        <small> <span className="text-muted">{t('word.ticketLevel')} </span>{ticketLevelName}</small>
                    </div>
                    <div className="col-sm-auto order-sm-1">
                        <ButtonElement
                            small
                            outline
                            additionalClass={`${advancedOpened ? 'collapse-opened' : 'dropdown-toggle'}`}
                            onClick={() => {
                                setAdvancedOpened(!advancedOpened)
                            }}
                        >
                            <span className="me-2">{t('button.organizerEventLevel')}</span>
                            <ChevronDownIcon/>
                        </ButtonElement>
                    </div>
                </div>
                <div className={`mb-4 collapse ${advancedOpened ? 'show' : ''}`}>
                    <div className="collapse-content">
                        <div className="mb-4">
                            <SelectOrganizer error={organizerError}/>
                        </div>
                        <div className="mb-4">
                            <SelectEvent error={eventError}/>
                        </div>
                        <div className="mb-4">
                            <SelectTicketLevel error={levelError}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SelectImageBlock/>
        <div className="row mb-3">
            <div className="col-lg-8">
                <div className="mb-4">
                    <InputElement
                        error={ticketName.error.status}
                        errorText={ticketName.error.text}
                        label={<>{t('form.label.ticketName')} <span className="text-danger">*</span></>}
                        value={ticketName.value}
                        onChange={onChangeTicketName}
                    />
                </div>
                <div className="mb-4">
                    <TextareaElement
                        error={ticketDescription.error.status}
                        errorText={ticketDescription.error.text}
                        label={<>{t('form.label.description')} <span className="text-danger">*</span></>}
                        value={ticketDescription.value}
                        onChange={onChangeDescription}
                        rows={5}
                    />
                </div>
            </div>
        </div>
        <PropertiesBlock/>
        {!showcaseType || showcaseType === 'classic' ?
            <div className="mb-6">
                <div className="row">
                    <div className="col-6 col-lg-2">
                        <div className="mb-4">
                            <InputElement
                                error={ticketBatch.error.status}
                                errorText={ticketBatch.error.text}
                                label={<>{t('form.label.batchAmount')} <span className="text-danger">*</span></>}
                                value={ticketBatch.value === null ? '' : ticketBatch.value.toString()}
                                onChange={onChangeBatchHandler}
                                additionalClass={'w-100'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            :
            null
        }
        {checkButtonDisabled() ?
            <div className="row">
                <div className="col-lg-8">
                    <AlertElement type={'danger'}>
                        <Trans i18nKey={'error.fillAllFields'} components={[<span className="text-danger"/>]}/>
                    </AlertElement>
                </div>
            </div>
            :
            null
        }
        <div className="row mb-6">
            <div className="col-lg-3">
                {wallet ?
                    user && user.auth ?
                        <ButtonElement
                            additionalClass={'w-100'}
                            onClick={submitHandler}
//                            disabled={checkButtonDisabled()}
                        >{t('button.mintTickets')}
                        </ButtonElement>
                        :
                        <SignAuthMessage/>
                    :
                    <ConnectWalletButton/>
                }
            </div>
        </div>
    </>
}

export default MintBlock
