import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
    getModalEditEvent,
    getWalletAddress, sendRequestWithAuth,
    setModalEditEvent
} from '../../store/appSlice'
import {ButtonElement, CheckboxGroupElement, DateElement, InputElement, TextareaElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon} from '../icons'
import {getCurrentEventId, getEvent, putEvent} from '../../store/eventsSlice'
import {getEventTypesList, requestEventTypes} from '../../store/calendarSlice'
import {DBCalendarEventType} from '../../store/types'

interface propsType {
    show: boolean
}

const ModalEditEvent = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [checkedTypes, setCheckedTypes] = useState<number[]>([])
    const [checkedTypesError, setCheckedTypesError] = useState('')
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const [eventTitle, setEventTitle] = useState(event?.title || '')
    const [titleError, setTitleError] = useState('')
    const [startDate, setStartDate] = useState<Date | null>(event?.startTime || null)
    const [startError, setStartError] = useState('')
    const [endDate, setEndDate] = useState<Date | null>(event?.endTime || null)
    const [endError, setEndError] = useState('')
    const [eventDescription, setEventDescription] = useState(event?.description || '')
    const modal = useSelector(getModalEditEvent)
    const typeList = useSelector(getEventTypesList)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!typeList.length) {
            dispatch(requestEventTypes())
        } else if (event) {
            let ids: number[] = []
            for (let item of event.types) {
                ids.push(item.id)
            }
            setCheckedTypes(ids)
        }
    }, [typeList])

    const closeModal = () => {
        dispatch(setModalEditEvent(false))
    }
    const editEventHandler = () => {
        if (!modal || !event) {
            return
        }

        let error = false
        if (!startDate) {
            error = true
            setStartError(t('error.wrong', {name: t('form.label.startDate')}))
        } else {
            setStartError('')
        }
        if (!endDate) {
            error = true
            setEndError(t('error.wrong', {name: t('form.label.endDate')}))
        } else if (endDate <= (startDate || 0)) {
            error = true
            setEndError(t('error.mustBeGreater', {name: t('form.label.endDate'), value: t('form.label.startDate')}))
        } else {
            setEndError('')
        }
        if (eventTitle === '') {
            error = true
            setTitleError(t('error.wrong', {name: t('form.label.eventTitle')}))
        } else {
            setTitleError('')
        }
        if (!checkedTypes.length) {
            error = true
            setCheckedTypesError(t('error.typeNotSelected'))
        } else {
            setCheckedTypesError('')
        }

        if (error || !walletAddress || !startDate || !endDate) {
            return
        }
        let types: DBCalendarEventType[] = []
        for (let item of typeList) {
            if (checkedTypes.indexOf(item.id) >= 0) {
                types.push({id: item.id, title: item.name})
            }
        }
        dispatch(sendRequestWithAuth(putEvent({
            id: event.id,
            title: eventTitle,
            url: event.url,
            description: eventDescription,
            startTime: startDate,
            endTime: endDate,
            types,
        })))
    }
    const eventTypesHandler = (id: any, checked: boolean) => {
        if (!checked) {
            setCheckedTypes(checkedTypes.filter((item) => item !== id))
        } else if (checkedTypes.indexOf(id) < 0) {
            setCheckedTypes([...checkedTypes, id])
        }
    }

    if (!modal) {
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit an event"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.editEvent')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <InputElement
                                    value={eventTitle}
                                    onChange={setEventTitle}
                                    errorText={titleError}
                                    label={t('form.label.eventTitle')}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={startDate}
                                    onChange={setStartDate}
                                    label={t('form.label.startDate')}
                                    errorText={startError}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={endDate}
                                    onChange={setEndDate}
                                    label={t('form.label.endDate')}
                                    errorText={endError}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-4">
                                <TextareaElement
                                    value={eventDescription}
                                    onChange={setEventDescription}
                                    label={t('form.label.eventDescription')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <CheckboxGroupElement
                                    checkedList={checkedTypes}
                                    list={typeList}
                                    onChange={eventTypesHandler}
                                    label={t('form.label.type')}
                                    errorText={checkedTypesError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        additionalClass={'flex-grow-1 flex-lg-grow-0'}
                        onClick={editEventHandler}
                    >{t('button.save')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalEditEvent
