import React from 'react'
import {Link} from 'react-router-dom'
import Tippy from '@tippyjs/react'
import {ethers} from 'ethers'
import {useTranslation} from 'react-i18next'
import {IEventByDay, isDBEventWithOrganizer} from '../../store/types'
import {compactString, getMonthList} from '../../utils/functions'

interface PropsType {
    events: IEventByDay[] | null
}

const CalendarList = ({events}: PropsType) => {
    const {t} = useTranslation()

    return <>
        {events ?
            events.length === 0 ?
                <div className="mt-3">
                    <div className="item">
                        <div className="row">
                            <div className="col-sm-12 mb-2">
                                {t('error.eventsNotFound')}
                            </div>
                        </div>
                    </div>
                </div>
                :
                events.map((item, index) => (
                    <React.Fragment key={index}>
                        {item.events.map((event, idx) => {
                            const date = event.startTime
                            const day = date.getDate()
                            const month = getMonthList()[date.getUTCMonth()].name
                            const year = date.getFullYear()
                            const dateStr = date.toString()
                            const dayOfWeek = dateStr.substring(0, dateStr.indexOf(' '))
                            const pos = dateStr.indexOf(':')
                            const time = dateStr.substring(pos - 2, pos + 3)
                            const timezone = dateStr.substring(dateStr.indexOf('GMT'))

                            return <div className="col-md-6 col-lg-4 col-xl-3 mb-4" key={idx}>
                                {isDBEventWithOrganizer(event) ?
                                    <Link to={`/event/${event.organizerUrl}/${event.url}`} className="event-preview">
                                        <div>
                                            <div className="d-flex mb-4">
                                                <span className="badge badge-sm text-bg-primary me-1">
                                                    {day} {month} {year}
                                                </span>
                                                <span className="badge badge-sm badge-outline-primary me-1">
                                                    {t('event.label.myshch')}
                                                </span>
                                                {event.types.map(item => (
                                                    <span className="badge badge-sm badge-outline-primary me-1"
                                                          key={item.id}>
                                                        {item.title}
                                                    </span>
                                                ))}
                                            </div>
                                            <div className="mb-4">
                                                <div className="h3 mb-1">{event.title}</div>
                                                <div className="d-flex text-primary small">
                                                    <span className="me-1">{t('event.card.by')} </span>
                                                    <Tippy
                                                        content={event.organizerTitle}
                                                        appendTo={document.getElementsByClassName("wrapper")[0]}
                                                        trigger='mouseenter'
                                                        interactive={false}
                                                        arrow={false}
                                                        maxWidth={512}
                                                    >
                                                        <span className="text-truncate">
                                                            {ethers.utils.isAddress(event.organizerTitle) ?
                                                                compactString(event.organizerTitle)
                                                                :
                                                                event.organizerTitle
                                                            }
                                                        </span>
                                                    </Tippy>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>{dayOfWeek}, {time}</div>
                                            <small className="text-muted">{timezone}</small>
                                        </div>
                                    </Link>
                                    :
                                    <Link to={`/calendar/events/${event.id}`} className="event-preview">
                                        <div>
                                            <div className="d-flex mb-4">
                                                <span className="badge badge-sm text-bg-primary me-1">
                                                    {date.getDate()} {getMonthList()[date.getUTCMonth()].name} {date.getFullYear()}
                                                </span>
                                                {event.types.map(item => (
                                                    <span className="badge badge-sm badge-outline-primary me-1"
                                                          key={item.id}>
                                                        {item.title}
                                                    </span>
                                                ))}
                                            </div>
                                            <div className="mb-4">
                                                <div className="h3 mb-1">{event.title}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>{dayOfWeek}, {time}</div>
                                            <small className="text-muted">{timezone}</small>
                                        </div>
                                    </Link>
                                }
                            </div>
                        })}
                    </React.Fragment>
                ))
            :
            <span>{t('status.loading')}...</span>
        }
    </>
}

export default CalendarList
