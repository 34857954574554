import {ReactNode} from 'react'
import {AsyncThunkAction, PayloadAction} from '@reduxjs/toolkit'
import {ethers} from 'ethers'

export interface DBCalendarEventType {
    id: number
    title: string
}

export interface DBEvent {
    id: number
    title: string
    url: string
    description: string | null
    startTime: Date
    endTime: Date
    showcases?: DBShowcase[]
    showcaseType?: string
    types: DBCalendarEventType[]
}

export interface DBEventWithOrganizer extends DBEvent {
    organizer: string
    organizerId: number
    organizerTitle: string
    organizerUrl: string
}

export interface DBOrganizer {
    id: number
    name: string
    url: string
}

export interface DBShowcase {
    id: number
    chain: number
    contract: string
    name: string
    eventId: number
    title: string
}

export interface DBCalendarEvent {
    description: string
    endTime: Date
    id: number
    links: ILink[]
    startTime: Date
    title: string
    types: DBCalendarEventType[]
}

export interface DBCalendarEventType {
    id: number
    title: string
}

export interface DBTicketLevel {
    id: number
    level: number
    title: string
}

export interface DBTicketWithEvent {
    id: number
    chain: number
    contract: string
    tokenId: string
    isUsed: boolean
    level: number
    organizer: string
    organizerUrl: string
    url: string
    eventName: string
    eventUrl: string
    shortDescription: string
}

export interface DBWhitelist {
    id: number
    network: string
    contract: string
    tokenId: bigint
    assetType: number
    eventId: number
    level: ILevel
    address: string
}

export interface IAsset {
    assetType: number
    contractAddress: string
}

export interface IAssetItem {
    asset: IAsset
    tokenId: bigint
    amount: number
}

export interface IAuthUser {
    address: string
    msg: string
    auth: boolean
    manager: boolean
    iat: number
    exp: number
}

export interface IBalance extends ICoin {
    balance: string
    units: bigint
}

export interface IBalances {
    [contract: string]: IBalance
}

export interface IBatchItemsParams {
    prices: IPrice[]
}

export interface ICalendarEvent {
    description: string
    endDate: number
    startDate: number
    title: string
    typeIds: number[]
    links: ILink[]
}

export interface ICheckedTicket {
    contract: string
    tokenId: bigint
    assetType: number
}

export interface ICoin {
    contract: string
    decimals: number
    token: string
}

export interface ICoinObject {
    [contract: string]: ICoin
}

export interface ICollection extends IAsset {
    name: string
}

export interface IconProps {
    className?: string
    notFill?: boolean
    onClick?: () => void
}

export interface IDiscounts {
    [key: string]: number   //key: `${contract}-${tokenId}`
}

export interface IDisplay extends IDisplayParams {
    items: IItemOnSale[]
    owner: string
    priceModel: string
}

export interface IDisplayParams {
    beneficiary: string
    disableAfter: number
    displayName: string
    enableAfter: number
    edit?: boolean
}

export interface IDropdownItem {
    id: any
    name: string
}

export interface IEditAssetItemPriceParams {
    oldPrices: IPrice[]
    newPrices: IPrice[]
    assetItem: IAssetItem
}

export interface IEvent {
    description?: string
    endTime?: number,
    name: string,
    startTime?: number,
    typeIds?: number[]
}

export interface IEventByOrganizer {
    id: number
    name: string
    status: number
}

export interface IEventByDay {
    day: number
    month: number
    year: number
    events: (DBEventWithOrganizer | DBCalendarEvent)[]
}

export interface IEventFilters {
    from?: number
    month?: number
    to?: number
    typeIds?: number[]
    year?: number
}

export interface IEventsObject {
    [eventId: number]: DBEventWithOrganizer
}

export interface IEventStats {
    usedTickets: number
    whitelistedTickets: number
}

export interface IEventTypesObject {
    [key: string]: DBCalendarEventType
}

export interface IGeneratedImage {
    description: string
    title: string
    url: string
}

export interface IInviteLinks {
    [ticketId: number]: string
}

export interface IItemOnSale {
    beneficiary: string
    nft: IAssetItem
    owner: string
    prices: IPrice[]
}

export interface ILevel {
    id: number
    title: string
    level: number
}

export interface ILink {
    title: string
    url: string
}

export interface InputData<T> {
    error: {
        status: boolean
        text: string
    }
    value: T
}

export interface IOnChainEventData {
    certificate: IPeriod
    eventContract: string
    eventName: string
    eventTicker: string
    sbtRules: string
    tickets: string
    useTicket: IPeriod
}

export interface IPeriod {
    finish: number
    start: number
}

export interface IPrice {
    payWith: string
    amount: ethers.BigNumber
}

export interface IProperty {
    trait_type: string
    value: string
}

export interface ISendTransaction {
    signedStatus?: boolean
    title: string
    trx: ITransaction
    trxId?: string
    afterSigningCallback?: () => void
    successfulSendingCallback?: (receipt?: ethers.providers.TransactionReceipt) => void
}

export interface IShowcase {
    blockNum: number
    contract: string
    disableAfter: Date
    enableAfter: Date
    hash: string
    id: number
    logIndex: number
    name: string
    priceModel: string
}

export interface IShowcaseV2Params {
    beneficiary: string
    disableAfter: number
    showcaseName: string
    enableAfter: number
    type: TShowcaseType
    edit?: boolean
}

export interface IShowcaseToEvent {
    contract: string
    name: string
    linkTitle: string
    network: string
}

export interface ITicket extends IToken {
    eventId: number
    ticketId: number
    level: ILevel
    isTicket: boolean
    isUsed: boolean
}

export interface ITicketPrice {
    price: string
    priceError: string
    decimals: number
    token: string
    customContract: string
    customContractError: string
}

export interface IToken {
    assetType: number
    blockNum: number
    contract: string
    date?: Date
    image?: string
    level?: ILevel
    name?: string
    network: string
    owner: string
    rules: number
    tokenId: bigint
    tokenUri: string
}

export interface ITransaction {
    from: string
    to: string
    data: string
    value?: ethers.BigNumber
    gasLimit?: ethers.BigNumber
}

export interface IVariables {
    [key: string]: TVariable
}

export interface IWalletQueue {
    request: AsyncThunkAction<any, any, any> | PayloadAction<any>
    network?: string
}

interface linkType {
    title: string
    url: string
    targetBlank?: boolean
}

export interface ModalAddEventToShowcaseType {
    contract: string
    showcaseName: string
    network: string
}

export interface ModalConfirmationType {
    confirmAction: () => void
    text?: string
    title: string
}

export interface ModalCreateShowcaseV2Type {
    type: TShowcaseType
}

export interface ModalEditStringType {
    handler: (text: string) => void
    label?: string
    text: string
    title?: string
}

export interface ModalEditTicketPricesType {
    prices: IPrice[] | ITicketPrice[]
    setPrices: (prices: ITicketPrice[]) => void
}

export interface ModalErrorType {
    title?: string
    text?: string[] | string
    buttons?: ('close' | 'chooseNetwork')[]
    links?: linkType[]
}

export interface ModalMintSbtType {
    assetType: number
    contract: string
    tokenId: bigint
}

export interface ModalSendTokenType {
    assetType: number
    contract: string
    tokenId: bigint
}

export interface ModalSendTransactionType {
    actionButton?: {
        title: string
        action: () => void
    }
    redirect?: {
        path: string,
        time: number,   //milliseconds
    }
    successButton?: {
        title?: string
        action?: () => void
    }
    successText?: ReactNode
    title?: string
    transactions: ISendTransaction[]
}

export interface ModalVideoType {
    title: string
    link: string
}

export interface SliceResponse {
    afterCheckCallback?: () => void
    beforeCheckCallback?: () => void
    error?: ModalErrorType
    data?: any
    defaultData?: any
    setData?: (data: any) => void
    status?: number | null
    successCallback?: () => void
}

export type TDesignMode = 'classic-mode' | 'smart-mode' | 'onchain-mode'

export type TShowcaseType = 'classic' | 'smart'

export type TVariable = string

export interface WhitelistedTicket {
    chain: number
    contract: string
    tokenId: bigint | string
    levelId: number
    assetType: number
}

export const initInputData = (value: any): InputData<any> => {
    return {error: {status: false, text: ''}, value}
}
export const isDBEventWithOrganizer = (variable: DBEventWithOrganizer | DBCalendarEvent): variable is DBEventWithOrganizer => {
    return typeof (variable as DBEventWithOrganizer).organizerTitle === 'string'
}
export const isIPrice = (variable: IPrice | ITicketPrice): variable is IPrice => {
    return typeof (variable as IPrice).payWith === 'string'
}
